<template>
	<v-row>
		<v-dialog scrollable persistent v-model="dialogProdutosSelecionados">
			<v-card>
				<v-card-title class="text-h5">Confirmação de produtos para impressão</v-card-title>
				<v-divider />
				<v-card-text>
					<v-card color="primary" dark class="my-3 rounded-0" elevation="0">
						<v-card-text class="pt-1 white--text">
							<v-row v-if="produtosImpressao.length > 0">
								<v-col class="font-weight-bold mt-3 text-h6 text-center">
									<span>Total de etiquetas:</span>
									<v-chip
										class="ml-3 mt-n1 primary--text"
										color="white"
									>{{produtosImpressao.map((v)=>v.quantidade).reduce((a,v) => a + v)}}</v-chip>
								</v-col>
								<v-col class="font-weight-bold mt-3 text-h6 text-center">
									<span>Total de páginas:</span>
									<v-chip class="ml-3 mt-n1 primary--text" color="white">{{calculaPaginas()}}</v-chip>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
					<v-simple-table>
						<thead>
							<tr>
								<th></th>
								<th class="text-left">ID</th>
								<th class="text-left">Produto</th>
								<th class="text-left">Quantidade</th>
							</tr>
						</thead>
						<tbody v-if="produtosImpressao.length > 0">
							<tr v-for="(p, i) in produtosImpressao" :key="i">
								<td>
									<v-btn color="error" fab x-small elevation="0" @click="delProdListaImpressao(p)">
										<v-icon>mdi-minus</v-icon>
									</v-btn>
								</td>
								<td class="text-truncate">{{p.idproduto}}</td>
								<td class="text-truncate">{{p.produto}}</td>
								<td class="text-truncate">
									<v-text-field
										style="width: 100px;"
										class="mb-n3"
										reverse
										dense
										type="number"
										@change="alterarQuantidade(p)"
										v-model.number="p.quantidade"
									></v-text-field>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td colspan="7" class="text-center">Nenhum produto selecionado</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-4">
					<v-spacer />
					<v-btn color="primary" elevation="0" @click="dialogProdutosSelecionados = false">Cancelar</v-btn>
					<v-btn
						v-if="produtosImpressao.length > 0"
						color="primary"
						elevation="0"
						@click="makePDF"
					>Confirmar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogCarregando" hide-overlay persistent>
			<v-card color="primary" dark>
				<v-card-text class="pa-5">
					Aguarde enquanto estamos gerando seu PDF
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-col cols="12">
			<v-card class="mt-3">
				<v-row class="px-3 px-lg-0 mb-n10">
					<v-col class="d-none d-md-block px-1 pl-lg-5">
						<InputDatePicker
							type="month"
							:disabled="carregando"
							v-model="busca.dtmes"
							label="Data"
							:outlined="true"
							:dense="true"
						/>
					</v-col>
					<v-col class="d-none d-md-block px-1">
						<v-autocomplete
							:items="produtos.lista"
							item-text="produto"
							item-value="idproduto"
							label="Produto"
							outlined
							dense
							clearable
							:disabled="carregando"
							v-model="busca.idproduto"
						></v-autocomplete>
					</v-col>
					<v-col class="d-none d-md-block px-1">
						<v-autocomplete
							:items="fabricantes.lista"
							item-text="fabricante"
							item-value="idfabricante"
							label="Fabricante"
							outlined
							dense
							clearable
							:disabled="carregando"
							v-model="busca.idfabricante"
						></v-autocomplete>
					</v-col>
					<v-col class="d-none d-md-block px-1">
						<v-autocomplete
							:items="secoes.lista"
							item-text="secao"
							item-value="idsecao"
							label="Seção"
							outlined
							dense
							clearable
							:disabled="carregando"
							v-model="busca.idsecao"
						></v-autocomplete>
					</v-col>
					<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
						<v-sheet class="text-center" height="100%">
							<v-row class="pa-3">
								<v-col cols="12" class="mb-n8">
									<v-select
										:items="[8,16,24,32,40,64,80,160]"
										dense
										outlined
										v-model.number="config.pgLimit"
										label="Produtos por busca"
										@change="listar(0)"
									/>
								</v-col>
								<v-col cols="6" class="mb-n8">
									<InputDatePicker
										type="month"
										:disabled="carregando"
										v-model="busca.dtmes"
										label="Data"
										:outlined="true"
										:dense="true"
									/>
								</v-col>
								<v-col cols="6" class="mb-n8">
									<v-autocomplete
										:items="produtos.lista"
										item-text="produto"
										item-value="idproduto"
										label="Produto"
										outlined
										dense
										clearable
										:disabled="carregando"
										v-model="busca.idproduto"
									></v-autocomplete>
								</v-col>
								<v-col cols="6" class="mb-n8">
									<v-autocomplete
										:items="fabricantes.lista"
										item-text="fabricante"
										item-value="idfabricante"
										label="Fabricante"
										outlined
										dense
										clearable
										:disabled="carregando"
										v-model="busca.idfabricante"
									></v-autocomplete>
								</v-col>
								<v-col cols="6" class="mb-n8">
									<v-autocomplete
										:items="secoes.lista"
										item-text="secao"
										item-value="idsecao"
										label="Seção"
										outlined
										dense
										clearable
										:disabled="carregando"
										v-model="busca.idsecao"
									></v-autocomplete>
								</v-col>
								<v-col cols="6">
									<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
								</v-col>
								<v-col cols="6">
									<v-btn text class="mt-n3" color="primary" @click="listar(0), sheet = !sheet">Filtrar</v-btn>
								</v-col>
							</v-row>
						</v-sheet>
					</v-bottom-sheet>
					<v-col class="px-1 pr-lg-5 d-flex">
						<v-autocomplete
							class="d-inline-flex d-md-none"
							:items="filiaisFiltro"
							item-text="filial"
							item-value="idfilial"
							label="Filial"
							outlined
							dense
							:disabled="carregando"
							v-model="busca.idfilial"
							append-outer-icon="mdi-menu"
							@click:append-outer="sheet = !sheet"
						></v-autocomplete>
						<v-autocomplete
							class="d-none d-md-block"
							:items="filiaisFiltro"
							item-text="filial"
							item-value="idfilial"
							label="Filial"
							outlined
							dense
							:disabled="carregando"
							v-model="busca.idfilial"
						></v-autocomplete>
						<v-btn
							:disabled="carregando"
							class="ml-2 mt-1"
							color="primary"
							elevation="0"
							fab
							x-small
							@click="listar(0)"
						>
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				<v-sheet height="65" class="px-3 mt-3 mb-0" color="primary" dark>
					<v-row>
						<v-col class="px-1 pl-lg-5">
							<v-text-field
								type="number"
								dense
								outlined
								v-model.number="config.quantidade"
								label="Quantidade por produto"
								@change="alterarTodasQuantidade()"
							/>
						</v-col>
						<v-col class="d-none d-md-block px-1">
							<v-select
								:items="[8,16,24,32,40,64,80,160]"
								dense
								outlined
								v-model.number="config.pgLimit"
								label="Produtos por busca"
								@change="listar(0)"
							/>
						</v-col>
						<!-- 
                <v-col class="d-none d-md-block px-1"><v-text-field type="number" dense outlined v-model.number="config.ml" label="Margem esquerda" /></v-col>
                <v-col class="d-none d-md-block px-1"><v-text-field type="number" dense outlined v-model.number="config.mt" label="Margem top" /></v-col>
                <v-col class="d-none d-md-block px-1"><v-text-field type="number" dense outlined v-model.number="config.fsCod" label="Fonte código" /></v-col>
                <v-col class="d-none d-md-block px-1"><v-text-field type="number" dense outlined v-model.number="config.fsDesc" label="Fonte descrição" /></v-col>
				<v-col class="d-none d-md-block px-1"><v-text-field type="number" dense outlined v-model.number="config.fsVal" label="Fonte valor" /></v-col>
						-->
						<v-col class="px-1 pr-lg-5">
							<v-btn
								elevation="0"
								color="white"
								class="font-weight-bold primary--text"
								light
								block
								height="39"
								@click="dialogProdutosSelecionados = true"
								:loading="carregando"
							>Gerar PDF</v-btn>
						</v-col>
					</v-row>
				</v-sheet>
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-text>
					<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
					<v-simple-table v-else dense>
						<thead>
							<tr>
								<th></th>
								<th class="text-left">ID</th>
								<th class="text-left">Produto</th>
								<th class="text-left">Quantidade</th>
								<th class="text-left">Cód. Barras</th>
								<th class="text-left">Valor</th>
								<th class="text-left">Valor em promoção</th>
								<th class="text-left">Início promoção</th>
								<th class="text-left">Fim promoção</th>
							</tr>
						</thead>
						<tbody>
							<template v-if="dados.total > 0">
								<tr v-for="(u, i) in dados.lista" :key="i">
									<td>
										<v-btn
											color="primary"
											fab
											x-small
											elevation="0"
											v-if="verificaLista(u)"
											@click="adcProdListaImpressao(u)"
										>
											<v-icon>mdi-plus</v-icon>
										</v-btn>
										<v-btn color="error" fab x-small elevation="0" v-else @click="delProdListaImpressao(u)">
											<v-icon>mdi-minus</v-icon>
										</v-btn>
									</td>
									<td class="text-truncate">{{u.idproduto}}</td>
									<td class="text-truncate">{{u.produto}}</td>
									<td class="text-truncate">
										<v-text-field
											class="mb-n3"
											reverse
											dense
											type="number"
											:disabled="verificaLista(u)"
											:append-icon="verificaLista(u) ? 'mdi-alert-circle' : ''"
											@change="alterarQuantidade(u)"
											v-model.number="u.quantidade"
										></v-text-field>
									</td>
									<td class="text-truncate">{{u.codigobarra}}</td>
									<td class="text-truncate">{{u.valprazo | formataDinheiro}}</td>
									<td class="text-truncate">{{u.valpromocao | formataDinheiro}}</td>
									<td class="text-truncate">{{u.datainipromocao}}</td>
									<td class="text-truncate">{{u.datafimpromocao}}</td>
								</tr>
							</template>
							<template v-else>
								<tr>
									<td class="text-center" colspan="9">Nenhum registro encontrado.</td>
								</tr>
							</template>
						</tbody>
					</v-simple-table>
					<Paginacao
						:total="dados.total"
						@atualizar="listar"
						:pgLimitInt="config.pgLimit"
						:carregando="carregando"
						:pg="pgSelecionada"
					/>
				</v-card-text>
				<v-progress-linear indeterminate absolute :active="carregando" />
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-card>
				<v-card-title>
					Produtos selecionados
					<v-spacer />
					<v-btn color="error" x-small fab elevation="0" @click="limpaListaImpressao()">
						<v-icon>mdi-delete-outline</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text>
					<v-simple-table>
						<thead>
							<tr>
								<th></th>
								<th class="text-left">ID</th>
								<th class="text-left">Produto</th>
								<th class="text-left">Quantidade</th>
								<th class="text-left">Cód. Barras</th>
								<th class="text-left">Valor</th>
								<th class="text-left">Valor em promoção</th>
								<th class="text-left">Início promoção</th>
								<th class="text-left">Fim promoção</th>
							</tr>
						</thead>
						<tbody v-if="produtosImpressao.length > 0">
							<tr v-for="(p, i) in produtosImpressao" :key="i">
								<td>
									<v-btn color="error" fab x-small elevation="0" @click="delProdListaImpressao(p)">
										<v-icon>mdi-minus</v-icon>
									</v-btn>
								</td>
								<td class="text-truncate">{{p.idproduto}}</td>
								<td class="text-truncate">{{p.produto}}</td>
								<td class="text-truncate">
									<v-text-field
										style="width: 100px;"
										class="mb-n3"
										reverse
										dense
										type="number"
										@change="alterarQuantidade(p)"
										v-model.number="p.quantidade"
									></v-text-field>
								</td>
								<td class="text-truncate">{{p.codigobarra}}</td>
								<td class="text-truncate">{{p.valprazo | formataDinheiro}}</td>
								<td class="text-truncate">{{p.valpromocao | formataDinheiro}}</td>
								<td class="text-truncate">{{p.datainipromocao}}</td>
								<td class="text-truncate">{{p.datafimpromocao}}</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td colspan="9" class="text-center">Nenhum produto selecionado</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import html2canvas from "html2canvas";
import InputDatePicker from "../Widgets/InputDatePicker";
import Paginacao from "../Widgets/Paginacao";
import jsPDF from "jspdf";

export default {
	name: "MarketingGerarPdf",
	mixins: [mixinFilial],
	components: { Paginacao, InputDatePicker },
	data: () => ({
		sheet: false,
		dialogCarregando: false,
		dialogProdutosSelecionados: false,
		carregando: false,
		carregandoSkeleton: true,
		dados: { lista: [], total: 0 },
		fabricantes: {},
		secoes: {},
		produtos: {},
		busca: {
			dtmes: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}`,
		},
		produtosImpressao: [],
		config: {
			ml: 40,
			mt: 30,
			fsCod: 12,
			fsDesc: 16,
			fsVal: 40,
			pgLimit: 40,
			quantidade: 1,
		},
		todos: true,
		pgSelecionada: 0,
	}),
	computed: {
		...mapState(["backendUrl", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		makePDF() {
			this.dialogCarregando = true;
			let htmlContent;
			htmlContent = `<div class="row" style="margin: 0; width: 592px; font-family: Arial, Helvetica, sans-serif;">`;
			let cont = 0;
			this.produtosImpressao.forEach((v) => {
				for (let i = 0; i < v.quantidade; i++) {
					cont++;
					htmlContent += `
                    <div class="col-6" style="position: relative; height: 210px; width: 296px;">
                        <div style="padding: ${this.config.mt}px 0 0 ${
						this.config.ml
					}px;  font-size: ${this.config.fsCod}px;">Cód: ${
						v.idproduto
					}</div>
                        <div style="padding: 0 40px 0 ${
							this.config.ml
						}px; font-size: ${
						this.config.fsDesc
					}px; font-weight:bold; line-height: ${
						this.config.fsDesc + 2
					}px;">${v.produto}</div>
                        <div style="padding: 0 0 0 ${
							this.config.ml + 5
						}px; display: flex;">
                            <span style="font-size: ${
								this.config.fsDesc + 2
							}px; padding-right: 2px; font-weight:bold; align-self: end; padding-bottom: ${
						this.config.fsDesc / 2
					}px;">R$</span>
                            <span style="font-size: ${
								this.config.fsVal
							}px; font-weight:bold;">${
						v.valpromocao.toFixed(2).toString().split(".")[0]
					}</span>
                            <span style="font-size: ${
								(this.config.fsVal / 3) * 2
							}px; font-weight:bold; margin-top: 2px;">,${
						v.valpromocao.toFixed(2).toString().split(".")[1]
					}</span>
                        </div>
                        <div style="position: absolute; top: 85%; width: 200px; padding: 0 0 0 ${
							this.config.ml + 5
						}px;  font-size: ${
						(this.config.fsCod / 6) * 3
					}px;">Promocão: ${v.datainipromocao} à ${
						v.datafimpromocao
					}</div>
                    </div>
                    `;
					if (Number.isInteger(cont / 8)) {
						htmlContent += ` <div class="col-12" style="height: 2px; width: 592px; margin: 0; padding: 0;"></div>`;
					}
				}
			});
			if (cont < 8) {
				for (let i = 0; i < cont - 8; i++) {
					htmlContent += ` <div class="col-6" style="height: 210px; width: 296px;"></div>`;
				}
			}
			htmlContent += `</div>`;
			window.html2canvas = html2canvas;
			let doc = new jsPDF("p", "pt", "a4");
			let data = new Date();
			doc.html(htmlContent, {
				callback: function (pdf) {
					pdf.save(
						`pdf_promocoes_${data.toISOString().split(".")[0]}.pdf`
					);
				},
			});
			let tempo;
			cont > 20 ? (tempo = cont * 75) : (tempo = 1500);
			setTimeout(() => (this.dialogCarregando = false), tempo);
		},
		listar(pg) {
			this.pgSelecionada = pg;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}produto/pdf/listar`, {
					limit: this.config.pgLimit,
					offset: this.config.pgLimit * pg,
					idfilial: this.busca.idfilial || null,
					idproduto: this.busca.idproduto || null,
					idfabricante: this.busca.idfabricante || null,
					idsecao: this.busca.idsecao || null,
					dtmes: `${this.busca.dtmes}-01 `,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
					this.carregandoSkeleton = false;
					this.dados.lista.map((vf) => {
						this.produtosImpressao.map((vd) => {
							if (vf.idproduto == vd.idproduto) {
								vf.quantidade = vd.quantidade;
							}
						});
					});
				})
				.catch(() => {
					this.dados = { total: 0 };
					this.carregando = false;
					this.carregandoSkeleton = false;
				});
		},
		listarFabricantes() {
			return axios
				.post(`${this.backendUrl}produto/fabricante/listar`)
				.then((res) => {
					this.fabricantes = res.data;
				});
		},
		listarSecoes() {
			return axios
				.post(`${this.backendUrl}produto/secao/listar`)
				.then((res) => {
					this.secoes = res.data;
				});
		},
		listarProdutos() {
			return axios
				.post(`${this.backendUrl}produto/promocao/listar`)
				.then((res) => {
					this.produtos = res.data;
				});
		},
		alterarTodasQuantidade() {
			this.config.quantidade =
				this.config.quantidade < 0 ? 0 : this.config.quantidade;
			this.dados.lista.map((v) => {
				v.quantidade = this.config.quantidade;
			});
			this.produtosImpressao.map((v) => {
				v.quantidade = this.config.quantidade;
			});
			localStorage.setItem(
				"produtosImpressao",
				JSON.stringify(this.produtosImpressao)
			);
		},
		alterarQuantidade(produto) {
			this.dados.lista.forEach((v) => {
				if (v.idproduto == produto.idproduto) {
					v.quantidade =
						produto.quantidade < 0 ? 0 : produto.quantidade;
					this.produtosImpressao.forEach((p) => {
						if (p.idproduto == produto.id) {
							p.quantidade =
								produto.quantidade < 0 ? 0 : produto.quantidade;
						}
					});
				}
			});
			localStorage.setItem(
				"produtosImpressao",
				JSON.stringify(this.produtosImpressao)
			);
		},
		adcProdListaImpressao(produto) {
			this.produtosImpressao.push(produto);
			localStorage.setItem(
				"produtosImpressao",
				JSON.stringify(this.produtosImpressao)
			);
		},
		delProdListaImpressao(produto) {
			this.produtosImpressao = this.produtosImpressao.filter(
				(p) => p != produto
			);
			localStorage.setItem(
				"produtosImpressao",
				JSON.stringify(this.produtosImpressao)
			);
		},
		limpaListaImpressao() {
			this.produtosImpressao = [];
			localStorage.removeItem("produtosImpressao");
		},
		verificaLista(produto) {
			return (
				!this.produtosImpressao.filter(
					(p) => p.idproduto == produto.idproduto
				).length > 0
			);
		},
		calculaPaginas() {
			let valor = Math.floor(
				this.produtosImpressao
					.map((v) => v.quantidade)
					.reduce((a, v) => a + v) / 8
			);
			let decimal =
				this.produtosImpressao
					.map((v) => v.quantidade)
					.reduce((a, v) => a + v) /
					8 -
				Math.floor(
					this.produtosImpressao
						.map((v) => v.quantidade)
						.reduce((a, v) => a + v) / 8
				);

			return decimal != 0 ? valor + 1 : valor;
		},
		async init() {
			const json = localStorage.getItem("produtosImpressao");
			const produtosImpressao = JSON.parse(json);
			this.produtosImpressao = produtosImpressao || [];
			this.busca.idfilial = this.usuario.idfilial;
			await this.listarFabricantes();
			await this.listarSecoes();
			await this.listarProdutos();
			await this.listar(0);
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>